/* You can add global styles to this file, and also import other style files */
body,
html {
  height: 100%;
}

body {
  margin: 0;
}

.adf-datatable-list .adf-datatable-body .adf-datatable-row {
  min-height: 27px !important;
}

.adf-datatable-cell-header .adf-datatable-cell-header-content {
  padding: 0 !important;
  margin: 0  !important;
}

.narrow-cell {
  max-width: 60px !important;
  min-width: 60px !important;
  position: relative;
  flex-grow: 0 !important;
}

.adf-sticky-header .adf-datatable-body {
  overflow-y:auto !important;
}

.adf-sticky-header .adf-datatable-body {
  scrollbar-width: none !important; /* dla Firefox */
}

.adf-sticky-header .adf-datatable-body ::-webkit-scrollbar {
  display: none !important;
}

.adf-datatable-list .adf-datatable-cell .adf-datatable-link .adf-datatable-cell-value, .adf-datatable-list .adf-datatable-cell-header .adf-datatable-link .adf-datatable-cell-value {
  padding-left: 0 !important;
}

.adf-sticky-header .adf-datatable-header{
  padding-right: 12px;
}

.adf-layout-header .mat-toolbar-single-row .adf-userinfo-container{
  margin-right: 0px !important;
  margin-left: 15px;
}

.adf-datatable-list .adf-datatable-cell--image{
  max-width: 30px !important;
  min-width: 25px !important;
  margin-right: 5px !important;
}
.adf-datatable-list .adf-datatable-cell-header.adf-no-grow-cell, .adf-datatable-list .adf-datatable-cell.adf-no-grow-cell {
  min-width: 120px !important;
  max-width: 120px !important;
}

.adf-datatable-list .adf-datatable-cell .adf-datatable-cell-container, .adf-datatable-list .adf-datatable-cell-header .adf-datatable-cell-container {
  margin-left: auto;
  margin-right: auto !important;
  padding: 0px !important;
}

.adf-datatable-list .adf-datatable-cell--image {
  margin-left: auto;
  margin-right: auto !important;
}

.statusColumn{
  max-width: 10ch !important;
  min-width: 10ch !important;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1 !important;
}

.column-modifiedOn{
  max-width: 140px !important;
  min-width: 140px !important;
}

.column-modifiedBy{
  max-width: 120px !important;
  min-width: 120px !important;
}

.column-in-shared{
  max-width: 120px !important;
  min-width: 120px !important;
}

.favorite-libaries-component .adf-sticky-header .adf-datatable-header {
  padding-right: 0px !important;
}

.libaries-component .adf-sticky-header .adf-datatable-header {
  padding-right: 0px !important;
}

.shared-component .adf-sticky-header .adf-datatable-header {
  padding-right: 0px !important;
}

.column-in-favorites{
  max-width: 120px !important;
  min-width: 120px !important;
}
.trashcan-component .adf-sticky-header .adf-datatable-header {
  padding-right: 0px !important;
}

.column-in-trash{
  max-width: 140px !important;
  min-width: 140px !important;
}

.adf-datatable-cell-header.adf-sortable {
padding-left: 0px !important;
}

.displayNone {
  display: none !important;
}

.mat-drawer.mat-sidenav.mat-drawer-opened {
  z-index: 1001;
}

.mat-snack-bar-container {
  background-color: blue !important;
}

@media (max-width: 568px) {
  // .statusColumn {
  //   // max-width: 45px !important;
  //   // min-width: 45px !important;
  //   // flex-grow: 0 !important;
  // }
  .narrow-cell{
    max-width: 45px !important;
    min-width: 45px !important;
    white-space: nowrap !important;
  }
  .adf-datatable-cell-header{
    font-size: 14px !important;
  }
  .adf-sticky-header .adf-datatable-header{
    padding-right: 0px !important;
  }
  .adf-datatable-list .adf-datatable-row{
    padding-right: 0px !important;
  }
  .adf-datatable-cell-header .adf-datatable-cell-header-content {
    column-gap: 0px !important;
  }
  .adf-datatable-cell-header .adf-datatable__header--sorted-asc:after, .adf-datatable-cell-header .adf-datatable__header--sorted-desc:after {
    left: 1px !important;
  }
}

@media (max-width: 420px) {
  .adf-layout-header .mat-toolbar-single-row .adf-userinfo-container{
    margin-left: 0px;
  }
}

@media (max-width: 400px) {
  .aca-toolbar-action{
    margin: 0px !important;
  }
}


.adf-viewer__display-name {
  width: 30vw !important;
}

@media (min-width: 768px) {
  .adf-viewer__display-name {
    width: 50vw !important;
  }
}


@media (min-width: 1024px) {
  .adf-viewer__display-name {
    width: auto !important;
  }
}

@media (max-width: 768px) {
  #viewer-scale-page-button {
    display: none !important;
  }
  .adf-pdf-viewer__toolbar {
    .mat-toolbar.mat-toolbar-single-row {
      button[data-automation-id="adf-thumbnails-button"] {
        display: none !important;
      }
    }
    .adf-toolbar-divider {
      display: none !important;
    }
    .adf-pdf-viewer__toolbar-page-selector {
      padding: 0 !important;
    }
  }
  .adf-pdf-viewer__toolbar-page-selector label {
    display: none !important;
  }
}

.epmwf-comments-widget {
  .adf-datatable-cell--text {
    span {
      word-break: break-word;
      padding-right: 10px;
    }
  }
  .adf-datatable-list {
    .adf-datatable-row {
      &:nth-child(even) {
        background-color: rgba(0, 0, 0, 0.04);
      }
      &:focus, &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
  }
}

.mat-form-field-required-marker {
  color: var(--theme-warn-color) !important;
}
